<template>
  <div class="home fixed-sn">
    <owgheader></owgheader>
    <div class="flexible-content" >
      <topnavbar />
      <main>
        <div>
          <mdb-container fluid>
            <mdb-container v-if="!isloading">
              <mdb-row>
                <mdb-col md="6">
                  <h1>Blacklist</h1>
                </mdb-col>
              </mdb-row>
              <mdb-alert color="danger" v-if="error">{{error}}</mdb-alert>
              <mdb-alert color="success" v-if="success">{{success}}</mdb-alert>
            </mdb-container>
            <div class="d-flex justify-content-center" v-if="isloading">
              <mdb-spinner big crazy multicolor />
            </div>
            <div v-else>
              <mdb-row>
                <mdb-col md="3">
                  <mdb-input class="mt-0" v-model="search" label="Search" />
                </mdb-col>
              </mdb-row>
              <mdb-datatable-2 v-model='paxes' responsiveMd @selected='choosePax($event)' selectable :searching="search" />
              <mdb-btn v-on:click="addPaxToBlacklist" class="float-right" color="primary">Add a new pax</mdb-btn>
            </div>
          </mdb-container>
        </div>
      </main>
      <mdb-modal :show="currentpax !== null" @close="currentpax = null" info size="lg">
        <mdb-modal-header class="text-center">
          <mdb-modal-title tag="h4" bold class="w-100">Details</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body class="mx-3" v-if="currentpax">
          <mdb-alert v-if="editerror" color="danger">{{editerror}}</mdb-alert>
          <mdb-row>
            <mdb-col md="6">
              <mdb-input v-model="currentpax.firstname" label="First name" />
            </mdb-col>
            <mdb-col md="6">
              <mdb-input v-model="currentpax.lastname" label="Last name" />
            </mdb-col>
            <mdb-col md="6">
              <mdb-input v-model="currentpax.birthdate" label="Birthdate" />
            </mdb-col>
            <mdb-col md="6">
              <mdb-input v-model="currentpax.gender" label="Gender" />
            </mdb-col>
            <mdb-col md="6">
              <mdb-input v-model="currentpax.nationality" label="Nationality" />
            </mdb-col>
          </mdb-row>
          <mdb-row>
            <mdb-col md="6">
              <mdb-input v-model="currentpax.eventdate" label="Event date" />
            </mdb-col>
            <mdb-col md="6">
              <mdb-input v-model="currentpax.eventflightnum" label="Event flight" />
            </mdb-col>
          </mdb-row>
          <mdb-row>
            <mdb-col md="6">
              <h4>Type</h4>
              <ul>
                <li v-for="(item,index) in currentpax.entrycodedesc" v-bind:key="index">{{item}}</li>
              </ul>
            </mdb-col>
            <mdb-col md="6">
              <h4>Action</h4>
              <ul>
                <li v-for="(item,index) in currentpax.actiondesc" v-bind:key="index">{{item}}</li>
              </ul>
            </mdb-col>
          </mdb-row>

          <mdb-input type="textarea" v-model="currentpax.notes" label="Notes" :rows="4" />

          <mdb-input type="textarea" v-model="currentpax.reference" label="Reference" :rows="4" />
        </mdb-modal-body>
        <mdb-modal-footer>
          <mdb-btn v-on:click="editentry" outline="success">Save</mdb-btn>
          <mdb-btn v-on:click="currentpax=null" outline="infos">Close</mdb-btn>
        </mdb-modal-footer>
      </mdb-modal>

      <mdb-modal :show="showaddpax" @close="showaddpax = false" info size="lg">
        <mdb-modal-header class="text-center">
          <mdb-modal-title tag="h4" bold class="w-100">Add new pax</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body class="mx-3">
          <mdb-alert v-if="adderror" color="danger">{{adderror}}</mdb-alert>
          <mdb-row>
            <mdb-col md="6">
              <mdb-input v-model="newpax.firstname" label="First name" />
            </mdb-col>
            <mdb-col md="6">
              <mdb-input v-model="newpax.lastname" label="Last name" />
            </mdb-col>
            <mdb-col md="6">
              <mdb-input v-model="newpax.birthdate" label="Birthdate" placeholder="YYYY-MM-DD" />
            </mdb-col>
            <mdb-col md="6">
              <mdb-input v-model="newpax.gender" label="Gender" />
            </mdb-col>
            <mdb-col md="6">
              <mdb-input v-model="newpax.nationality" label="Nationality" />
            </mdb-col>
            <mdb-col md="6">
              <mdb-input v-model="newpax.expirationdate" label="Expiration date" placeholder="YYYY-MM-DD" />
            </mdb-col>
          </mdb-row>
          <mdb-row>
            <mdb-col md="6">
              <mdb-input v-model="newpax.eventdate" label="Event date" placeholder="YYYY-MM-DD" />
            </mdb-col>
            <mdb-col md="6">
              <mdb-input v-model="newpax.eventflightnum" label="Event flight" />
            </mdb-col>
          </mdb-row>
          <mdb-row>
            <mdb-col md="6">
              <h4>Type</h4>
              <mdb-input
                v-for="(item,index) in dropdowntypes" v-bind:key="index"
                class="my-2"
                type="checkbox"
                v-model="dropdowntypes[index].selected"
                :id="`type${index}`"
                :label="item.desc"
              />
            </mdb-col>
            <mdb-col md="6">
              <h4>Action</h4>
              <mdb-input
                v-for="(item,index) in dropdownactions" v-bind:key="index"
                class="my-2"
                type="checkbox"
                v-model="dropdownactions[index].selected"
                :id="`action${index}`"
                :label="item.desc"
              />
            </mdb-col>
          </mdb-row>
          <hr />
          <mdb-input type="textarea" v-model="newpax.notes" label="Notes" :rows="4" />
          <hr />
          <h4>Reference</h4>
          <mdb-input type="textarea" v-model="newpax.reference" label="Reference" :rows="2" />
        </mdb-modal-body>
        <mdb-modal-footer>
          <mdb-btn v-on:click="savePaxToBlacklist()" outline="success">Save</mdb-btn>
        </mdb-modal-footer>
      </mdb-modal>
    </div>
  </div>
</template>

<script>
import {
  mdbContainer,
  mdbAlert,
  mdbRow,
  mdbCol,
  mdbBtn,
  mdbSpinner,
  mdbDatatable2,
  mdbInput,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter,
} from 'mdbvue';
import owgheader from '@/components/Header.vue';
import topnavbar from '@/components/Topnavbar.vue';
import Apicall from '@/libs/Apicall';

export default {
  name: 'holasunflightdetails',
  data() {
    return {
      error: null,
      adderror: null,
      editerror: null,
      success: null,
      isloading: true,
      search: '',
      currentpax: null,
      showaddpax: false,
      newpax: {},
      dropdowntypes: [],
      dropdownactions: [],
      paxes: {
        columns: [
          {
            label: 'Firstname',
            field: 'firstname',
            sort: true,
          },
          {
            label: 'Lastname',
            field: 'lastname',
            sort: true,
          },
          {
            label: 'Birthdate',
            field: 'birthdate',
            format: (value) => new Date(value).toISOString().split('T')[0],
            sort: true,
          },
          {
            label: 'Expiration',
            field: 'expirationdate',
            format: (value) => value.split('T')[0],
            sort: true,
          },
          {
            label: 'Source',
            field: 'user_id',
            format: (value) => (parseInt(value, 10) > 0 ? 'OWG' : 'TRANSAT'),
            sort: true,
          },
        ],
        rows: [],
      },
    };
  },
  components: {
    owgheader,
    topnavbar,
    mdbContainer,
    mdbAlert,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbSpinner,
    mdbDatatable2,
    mdbInput,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
  },
  mounted() {
    this.loadlist();
  },
  methods: {
    loadlist() {
      const self = this;
      const api = new Apicall();
      self.paxes.rows = [];
      self.isloading = true;
      api.call('GET', 'api/blacklist/get-list').then((response) => {
        if (response.status === 'OK') {
          self.paxes.rows = response.data;
        } else {
          self.error = response.msg;
        }
        self.isloading = false;
      });
    },
    addPaxToBlacklist() {
      const api = new Apicall();
      const self = this;
      api.call('GET', 'api/blacklist/get-types-and-actions').then((response) => {
        self.dropdowntypes = response.data.types;
        self.dropdowntypes.map((obj) => { const a = obj; a.selected = false; return a; });
        self.dropdownactions = response.data.actions;
        self.dropdownactions.map((obj) => { const a = obj; a.selected = false; return a; });
        self.newpax = {};
        self.showaddpax = true;
        self.adderror = null;
      });
    },
    savePaxToBlacklist() {
      const api = new Apicall();
      const self = this;
      this.adderror = null;
      api.call('POST', 'api/blacklist/add', { pax: this.newpax, actions: this.dropdownactions, types: this.dropdowntypes }).then((response) => {
        if (response.status === 'OK') {
          self.success = 'Pax added succesfully';
          self.loadlist();
          self.showaddpax = false;
        } else {
          self.adderror = response.msg;
        }
      });
    },
    choosePax(ev) {
      this.editerror = null;
      this.currentpax = null;
      if (ev) {
        const api = new Apicall();
        const self = this;
        api.call('POST', 'api/blacklist/details', { blacklistid: ev.id }).then((response) => {
          if (response.status === 'OK') {
            self.currentpax = response.data;
          } else {
            const rootEle = document.documentElement;
            rootEle.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
            self.error = response.msg;
          }
        });
      }
    },
    editentry() {
      const api = new Apicall();
      const self = this;
      api.call('POST', 'api/blacklist/edit', { pax: this.currentpax, id: this.currentpax.id }).then((response) => {
        if (response.status === 'OK') {
          self.currentpax = null;
          self.loadlist();
          self.editerror = null;
        } else {
          self.editerror = response.msg;
        }
      });
    },
  },
};
</script>
