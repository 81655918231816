<template>
  <div class="home fixed-sn">
    <owgheader></owgheader>
    <div class="flexible-content" >
      <topnavbar />
      <main>
        <div>
          <mdb-container fluid>
            <h1>QR code scanner (SMART HEALTH)</h1>
            <mdb-row>
              <mdb-col md="5">
                <mdb-card>
                  <mdb-card-header color="grey darken-3">Image</mdb-card-header>
                  <mdb-card-body>
                    <mdb-file-input ref="inputfilehndl" @getValue="getFileInputValue" :width="300" btnColor="mdb-color" rounded btn-title="choose QR code image" img src="https://mdbootstrap.com/img/Photos/Others/placeholder.webp" />
                    <canvas ref="imageditor" style="display: none;"></canvas>
                  </mdb-card-body>
                </mdb-card>
              </mdb-col>
              <mdb-col md="7">
                <mdb-card>
                  <mdb-card-header color="grey darken-3">Résultat du scan</mdb-card-header>
                  <mdb-card-body>
                    <mdb-alert color="danger" v-if="error">{{error}}</mdb-alert>
                    <div v-if="candisplayresults">
                      <mdb-alert color="success" v-if="results.isok">PASSPORT OK</mdb-alert>
                      <mdb-alert color="danger" v-else>PASSPORT NOT OK</mdb-alert>
                      <mdb-alert color="info" class="mt-4">
                        Authenticated: <span v-if="numericsigned.validated">YES</span><span v-else>NO</span>
                        <br />
                        Signed by : {{numericsigned.issuer}}
                      </mdb-alert>
                      <h2>Patient</h2>
                      <mdb-row>
                        <mdb-col md="4">
                          Prénom
                        </mdb-col>
                        <mdb-col md="8">
                          {{results.patient.name.given}}
                        </mdb-col>
                      </mdb-row>
                      <mdb-row>
                        <mdb-col md="4">
                          Nom
                        </mdb-col>
                        <mdb-col md="8">
                          {{results.patient.name.family}}
                        </mdb-col>
                      </mdb-row>
                      <mdb-row>
                        <mdb-col md="4">
                          Date de naissance
                        </mdb-col>
                        <mdb-col md="8">
                          {{results.patient.birthdate}}
                        </mdb-col>
                      </mdb-row>
                      <h2>Immunization</h2>
                      <mdb-alert color="info" v-if="!results.immunization.length">Aucune immunisation</mdb-alert>
                      <div v-for="(thedate,index) in results.immunization" v-bind:key="index">
                        <mdb-row>
                          <mdb-col md="4">
                            Date
                          </mdb-col>
                          <mdb-col md=8>
                            {{thedate.effectiveDate.split('T')[0]}}
                          </mdb-col>
                        </mdb-row>
                        <mdb-row>
                          <mdb-col md="4">
                            Vaccin
                          </mdb-col>
                          <mdb-col md=8>
                            <strong>{{thedate.manufacturer}}</strong>
                            <br />
                            {{thedate.productName}}
                          </mdb-col>
                        </mdb-row>
                        <hr />
                      </div>
                      <h2>Diagnostic report</h2>
                      <mdb-alert color="info" v-if="!results.diagnosticreport.length">Aucun diagnostic</mdb-alert>
                      <mdb-row v-for="(thedate,index) in results.diagnosticreport" v-bind:key="index">
                        <mdb-col md="4">
                          Date
                        </mdb-col>
                        <mdb-col md=8>
                          {{thedate.effectiveDate.split('T')[0]}} ({{thedate.conclusion}})
                        </mdb-col>
                      </mdb-row>
                    </div>
                  </mdb-card-body>
                </mdb-card>
              </mdb-col>
            </mdb-row>
          </mdb-container>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbAlert,
  mdbCard,
  mdbCardHeader,
  mdbCardBody,
  mdbFileInput,
} from 'mdbvue';
import owgheader from '@/components/Header.vue';
import topnavbar from '@/components/Topnavbar.vue';
import QrScanner from 'qr-scanner';
import Apicall from '@/libs/Apicall';

export default {
  name: 'QrCodeScanner',
  data() {
    return {
      error: null,
      canvas: null,
      filereader: null,
      myimg: null,
      ctx: null,
      qrcodes: [],
      results: {
        patient: {},
        immunization: [],
        diagnosticreport: [],
        isok: false,
      },
      numericsigned: null,
      candisplayresults: false,
    };
  },
  components: {
    owgheader,
    topnavbar,
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbAlert,
    mdbCard,
    mdbCardHeader,
    mdbCardBody,
    mdbFileInput,
  },
  methods: {
    getFileInputValue(v) {
      this.candisplayresults = false;
      const tmp = v[0];
      this.qrcodes = [];
      this.myimg = tmp;
      this.filereader = new FileReader();
      this.filereader.onloadend = () => {
        this.img = new Image();
        this.img.onload = () => {
          this.$refs.imageditor.width = this.img.width;
          this.$refs.imageditor.height = this.img.height;
          this.canvas = this.$refs.imageditor;
          this.ctx = this.canvas.getContext('2d');
          this.ctx.drawImage(this.img, 0, 0);
          this.scanTheCanvas();
        };
        this.img.src = this.filereader.result;
      };
      this.filereader.readAsDataURL(this.myimg);
    },
    scanTheCanvas() {
      const self = this;
      QrScanner.scanImage(this.$refs.imageditor, { returnDetailedScanResult: true }).then((res) => {
        const points = self.findRect(res.cornerPoints);
        this.canvas = this.$refs.imageditor;
        this.ctx = this.canvas.getContext('2d');
        this.ctx.fillRect(points.x, points.y, points.width, points.height);
        self.qrcodes.push(res.data);
        if (self.qrcodes.length < 2) {
          self.scanTheCanvas();
        } else {
          self.validateAndPushToServer();
        }
      }).catch((res) => {
        self.validateAndPushToServer();
      });
    },
    findRect(points) {
      let minX = null;
      let minY = null;
      let maxX = null;
      let maxY = null;
      points.forEach((point) => {
        if (minX === null || point.x < minX) {
          minX = point.x;
        }
        if (maxX === null || point.x > maxX) {
          maxX = point.x;
        }
        if (minY === null || point.y < minY) {
          minY = point.y;
        }
        if (maxY === null || point.y > maxY) {
          maxY = point.y;
        }
      });
      return {
        x: minX,
        y: minY,
        width: maxX - minX,
        height: maxY - minY,
      };
    },
    validateAndPushToServer() {
      this.error = null;
      const self = this;
      const api = new Apicall();
      if (this.qrcodes.length === 0) {
        this.error = 'Aucun code QR détecté dans l\'image';
      } else {
        api.call('POST', 'api/tools/qrcodescanner', { qrcodes: this.qrcodes }).then((response) => {
          if (response.status === 'OK') {
            self.results.patient = response.data.shc.patient;
            self.results.immunization = response.data.shc.immunization;
            self.results.diagnosticreport = response.data.shc.diagnosticreport;
            self.results.isok = response.data.shc.isok;
            self.numericsigned = response.data.validation;
            self.candisplayresults = true;
          } else {
            this.error = response.msg;
          }
        });
      }
    },
  },
};
</script>
